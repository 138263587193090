import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

import IconCall from "../images/icons/theme/communication/call-1.svg";
import IconMail from "../images/icons/theme/communication/send.svg";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";

const SalesrepPage = ({ data, location: { pathname } }) => (
  <Layout headerBgClass="bg-primary-alt" hideFooter footerBgClass="bg-white" meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">Business Development Manager<br/>Full-Time - 100% Remote</h1>
              <div className="lead">
                <p>We are looking for a talented and competitive Business Development Manager, who will play a fundamental
                  role in achieving our ambitious customer acquisition and revenue growth objectives.
                </p>
              </div>

              <p className="lead mb-2"><strong>Responsibilities:</strong></p>
              <ul className="lead mb-5">
                <li>Create business development and sales strategies</li>
                <li>Target new leads using Sales Nav, Crunchbase, etc.</li>
                <li>Research accounts, identify key players and generate interest</li>
                <li>Source new sales opportunities through inbound lead follow-up and outbound calls and emails</li>
                <li>Understand customer needs and requirements</li>
                <li>Close sales and achieve quotas</li>
                <li>Perform effective online presentation (UX & Design services) to prospects</li>
                <li>Manage and grow existing accounts</li>
              </ul>


              <p className="lead mb-2"><strong>Requirements:</strong></p>
              <ul className="lead mb-5">
                <li>Excellent verbal and written communications skills (English C2)</li>
                <li>Proven inside sales experience</li>
                <li>Track record of over-achieving quota</li>
                <li>Strong phone/call presence</li>
                <li>Strong listening and presentation skills</li>
                <li><strong>Experience with (selling) UX / UI Design services is a plus.</strong></li>
              </ul>


            </div>

            {/* <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_29"></div>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </section>
    </div>

    {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=29")}
  </Layout>
);

export default SalesrepPage;

export const query = graphql`
  query salesrepPage {
    datoCmsPage(slug: { eq: "business-development-sales" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
